<template>
  <a-modal :visible="windowShow" width="40%" title="承运商详情与审核" footer="" @cancel="closeWindow">
    <div style="position:reactive">
      <div>
        <a-row :gutter="[24, 2]">
          <a-col :span="4">主体类型</a-col>
          <a-col :span="8">{{ detailData.carrierNatureType?.label }}</a-col>
          <a-col :span="4">承运商类型</a-col>
          <a-col :span="8">{{ detailData.carrierType?.label }}</a-col>
          <a-col :span="4">承运商名称</a-col>
          <a-col :span="8">{{ detailData.name }}</a-col>
          <a-col :span="4" v-show="detailData.carrierType?.value === 1">承运商别名</a-col>
          <a-col :span="8" v-show="detailData.carrierType?.value === 1">{{ detailData.alias }}</a-col>
          <a-col :span="4">结算方式</a-col>
          <a-col :span="8">{{ detailData.settlementMode?.label }}</a-col>
          <a-col :span="4">是否自有大板</a-col>
          <a-col :span="8">{{ detailData.isBigCar?.label }}</a-col>
          <a-col :span="4">负责人姓名</a-col>
          <a-col :span="8">{{ detailData.charge }}</a-col>
          <a-col :span="4">负责人电话</a-col>
          <a-col :span="8">{{ detailData.chargeMobile }}</a-col>
          <a-col :span="4">营业执照</a-col>
          <a-col :span="8"> <a-image style="width:20px;height:20px"
              :src="detailData.licenseFileUrl?.previewUrl || require('@/assets/images/nullImg.jpg')" /></a-col>
          <a-col :span="4">法人身份证</a-col>
          <a-col :span="8"> <a-image style="width:20px;height:20px"
              :src="detailData.fileUrl?.previewUrl || require('@/assets/images/nullImg.jpg')" /></a-col>
          <a-col :span="4">业务类型</a-col>
          <a-col :span="8">{{ detailData.carrierBusinessType?.label }}</a-col>
          <a-col :span="4">运力属性</a-col>
          <a-col :span="8">{{ detailData.carrierCapacityType?.label }}</a-col>
          <a-col :span="4">是否为固化</a-col>
          <a-col :span="8">{{ detailData.isSettled?.label }}</a-col>
          <a-col :span="4">提交人</a-col>
          <a-col :span="8">{{ detailData.creator }}</a-col>
          <a-col :span="4">提交时间</a-col>
          <a-col :span="8">{{ detailData.createTime }}</a-col>
          <a-col :span="4">承运方对接人</a-col>
          <a-col :span="8">{{ detailData.dockingPerson }}</a-col>
          <a-col :span="4">承运方对接人电话</a-col>
          <a-col :span="8">{{ detailData.dockingMobile }}</a-col>
          <a-col :span="4">标签</a-col>
          <a-col :span="8">{{ detailData.tagName }}</a-col>
          <a-col :span="4">常用地址</a-col>
          <a-col :span="8">{{ detailData.addressAll }}</a-col>
          <a-col :span="4">备注</a-col>
          <a-col :span="8">{{ detailData.remark }}</a-col>
        </a-row>
        <div style="margin-top:20px" v-show="detailData.status?.value === 0">
          <h2>审批</h2>
          <div style="color:#6B778C"><span style="color:red">*</span>审批结果</div>
          <a-select ref="select" v-model:value="approvalForm.status" style="width: 120px" @focus="focus"
            @change="handleChange">
            <a-select-option :value="1">同意</a-select-option>
            <a-select-option :value="2">拒绝</a-select-option>
          </a-select>
          <div style="color:#6B778C" class="m-t1"><span v-show="approvalForm.status==2" style="color:red">*</span>原因</div>
          <a-textarea v-model:value="approvalForm.refuseReason" :rows="4" />
          <div style="text-align:right;margin-top:10px">
            <a-button type="primary" size="large" :loading="btnLoading" @click="onApproval">确认</a-button>
          </div>
        </div>
        <div style="margin-top:20px;color: #6B778C;" v-show="detailData.status?.value !== 0">
          <p>
            审批结果：{{ detailData.status?.label }}
          </p>
          <p>
            审批人：{{ detailData.auditor || '' }}
          </p>
          <p>
            审批时间：{{ detailData.auditTime || '' }}
          </p>
        </div>
      </div>
      <div v-show="loading" class="loading-div">
        <a-spin size="large" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { detailData } from '@/api/carrier/detailData'
import { message } from 'ant-design-vue'
import { approval as approvalAdd } from '@/api/carrier/carrierList'

export default {
  props: {
    carrierId: String,
    windowShow: Boolean
  },
  setup (props, context) {
    const state = reactive({
      windowShow: false,
      btnLoading: false,
      loading: false,
      detailData: {},
      approvalForm: {
        status: null,
        refuseReason: ''
      }
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      if (newVal !== false) {
        state.windowShow = newVal
        state.carrierId = props.carrierId
        if (state.windowShow === true) loadData()
      }
    })
    const loadData = () => {
      state.loading = true
      detailData(state.carrierId).then(res => {
        if (res.code === 10000) {
          state.detailData = res.data
        }
      }).finally(() => { state.loading = false })
    }
    const closeWindow = () => {
      context.emit('statusShow', false)
      setTimeout(()=>{
        state.approvalForm.status = null
        state.approvalForm.refuseReason = ''
      },1000)
    }
    // 审核
    const onApproval = () => {
      if (!state.approvalForm.status) {
        message.error('请选择审批结果')
        return
      }
      if(state.approvalForm.status == 2) {
        if(!state.approvalForm.refuseReason) {
          message.error('请填写拒绝原因')
          return
        }
      }
      state.btnLoading = true
      approvalAdd({...state.approvalForm,carrierId:props.carrierId}).then(res => {
        if (res.code === 10000) {
          message.info(res.msg)
          context.emit('changeData')
          closeWindow()
        }
      }).finally(() => { state.btnLoading = false })
    }

    return {
      ...toRefs(state),
      closeWindow,
      onApproval
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-col) {
  color: #6B778C;
  font-size: 14px;
}

.loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, .8)
}
</style>
